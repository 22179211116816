import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Helmet } from 'react-helmet';

import './styles.css'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<>
    <Helmet>
      <meta http-equiv="ScreenOrientation" content="autoRotate:disabled"/>
      <title>Camel 3D</title>
    </Helmet>
    <App />
</>
    
);

reportWebVitals();

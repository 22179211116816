import React, { useEffect, useRef, useState, useMemo } from 'react';
import * as THREE from 'three'

import { useFrame,useThree } from "@react-three/fiber";
import { Html } from "@react-three/drei";

import { useLoader } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import {  OrbitControls } from "@react-three/drei";
import { motion } from 'framer-motion'
import { AiOutlineHighlight } from 'react-icons/ai'


const Gyrobox = () => {
  const inputElement = useRef();
  const [gyroscopeData, setGyroscopeData] = useState({ alpha: 0, beta: 0, gamma: 0 });
  const { camera } = useThree();


  const handleDeviceOrientation = useMemo(() => {
    return (event) => {
      setGyroscopeData({ alpha: event.alpha, beta: event.beta, gamma: event.gamma });
    };
  }, []); 

  useEffect(() => {
    window.addEventListener('deviceorientation', handleDeviceOrientation);
    return () => {
      window.removeEventListener('deviceorientation', handleDeviceOrientation);
    };
  }, [handleDeviceOrientation]); 

  const orbitRadius = 5;
  const [targetX, setTargetX] = useState(0);
  const [targetY, setTargetY] = useState(0);
  const [currentX, setCurrentX] = useState(0);
  const [currentY, setCurrentY] = useState(0);

  const sensitivity = 0.01;
  const maxBeta = Math.PI / 6;
  const minBeta = -Math.PI / 6;

  useFrame(() => {
    if (inputElement.current && camera) {
      const scaledBeta = gyroscopeData.beta * sensitivity;
      const clampedBeta = Math.min(Math.max(scaledBeta, minBeta), maxBeta);
      const clampedGamma = gyroscopeData.gamma * sensitivity;

      if (clampedBeta >= minBeta && clampedBeta <= maxBeta) {
        const targetXPos = orbitRadius * Math.sin(clampedBeta) * Math.cos(clampedGamma) - 1;
        const targetYPos = orbitRadius * Math.sin(clampedBeta) * Math.sin(clampedGamma) * 2;

        setTargetX(targetXPos);
        setTargetY(targetYPos);
      }
    }

    setCurrentX(currentX + (targetX - currentX) * 0.2); 
    setCurrentY(currentY + (targetY - currentY) * 0.2);

    camera.position.set(currentY, currentX, orbitRadius);
    camera.lookAt(0, 0, 0);
  });

  const requestGyroscopePermission = () => {
    if (window.DeviceMotionEvent && typeof window.DeviceMotionEvent.requestPermission === 'function') {
      if (typeof DeviceMotionEvent.requestPermission === 'function') {
        DeviceMotionEvent.requestPermission()
          .then(permissionState => {
            if (permissionState === 'granted') {
            } else {
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    } else {
    }
  };

  const handleBoxClick = () => {
    requestGyroscopePermission();
  };

  const gltf = useLoader(GLTFLoader, '/camelabyeth.gltf');
  

  gltf.nodes.Cube001.castShadow = true; 
  gltf.nodes.Cube001.receiveShadow = true;
  
  gltf.nodes.Cube002.castShadow = true; 
  gltf.nodes.Cube002.receiveShadow = true;

  gltf.nodes.Bone.castShadow = true; 
  gltf.nodes.Bone.receiveShadow = true;
  
  gltf.nodes.Armature.castShadow = true; 
  gltf.nodes.Armature.receiveShadow = true;


  gltf.nodes.Aset_building__M_wmcpdjzdw_00_LOD0.castShadow = true; 
  gltf.nodes.Aset_building__M_wmcpdjzdw_00_LOD0.receiveShadow = true;

  gltf.nodes.Aset_building__M_wmfiaaldw_00_LOD0.castShadow = true; 
  gltf.nodes.Aset_building__M_wmfiaaldw_00_LOD0.receiveShadow = true;

  gltf.nodes.Aset_building__M_wmfiaaldw_00_LOD0001.castShadow = true; 
  gltf.nodes.Aset_building__M_wmfiaaldw_00_LOD0001.receiveShadow = true;

  gltf.nodes.Aset_building__M_wmfiaaldw_00_LOD0002.castShadow = true; 
  gltf.nodes.Aset_building__M_wmfiaaldw_00_LOD0002.receiveShadow = true;

  gltf.nodes.Aset_building__M_wmfiaaldw_01_LOD0.castShadow = true; 
  gltf.nodes.Aset_building__M_wmfiaaldw_01_LOD0.receiveShadow = true;

  gltf.nodes.Aset_building__M_wmfiaaldw_01_LOD0001.castShadow = true; 
  gltf.nodes.Aset_building__M_wmfiaaldw_01_LOD0001.receiveShadow = true;

  gltf.nodes.Aset_building__M_wmfiaaldw_02_LOD0.castShadow = true; 
  gltf.nodes.Aset_building__M_wmfiaaldw_02_LOD0.receiveShadow = true;

  gltf.nodes.Aset_building_balcony_M_xcqpecidw_00_LOD0.castShadow = true; 
    gltf.nodes.Aset_building_balcony_M_xcqpecidw_00_LOD0.receiveShadow = true;

  gltf.nodes.Aset_building_beam_L_vkbfdekqx_00_LOD4.castShadow = true; 
  gltf.nodes.Aset_building_beam_L_vkbfdekqx_00_LOD4.receiveShadow = true;

  gltf.nodes.Aset_building_beam_L_vkbfdekqx_00_LOD4001.castShadow = true; 
  gltf.nodes.Aset_building_beam_L_vkbfdekqx_00_LOD4001.receiveShadow = true;

  gltf.nodes.Aset_building_beam_L_vkbfdekqx_00_LOD4002.castShadow = true; 
  gltf.nodes.Aset_building_beam_L_vkbfdekqx_00_LOD4002.receiveShadow = true;

  gltf.nodes.Aset_building_beam_L_vkbfdekqx_00_LOD4003.castShadow = true; 
  gltf.nodes.Aset_building_beam_L_vkbfdekqx_00_LOD4003.receiveShadow = true;

  gltf.nodes.Aset_historical_roman_empire_M_wj3keakqx_00_LOD0.castShadow = true; 
  gltf.nodes.Aset_historical_roman_empire_M_wj3keakqx_00_LOD0.receiveShadow = true;

  gltf.nodes.Aset_historical_roman_empire_M_wj3keakqx_00_LOD0001.castShadow = true; 
  gltf.nodes.Aset_historical_roman_empire_M_wj3keakqx_00_LOD0001.receiveShadow = true;

  gltf.nodes.Ba3T.castShadow = true; 
  gltf.nodes.Ba3T.receiveShadow = true;

  gltf.nodes.BezierCircle.castShadow = true; 
  gltf.nodes.BezierCircle.receiveShadow = true;

  gltf.nodes.Plane.receiveShadow = true;

  gltf.nodes.Plane002.receiveShadow = true;

  gltf.nodes.Bone.castShadow = true; 
  gltf.nodes.Bone.receiveShadow = true;

  for (let i = 1; i <= 32; i++) {
    let boneNumber = i.toString().padStart(3, '0');
    gltf.nodes[`Bone${boneNumber}`].castShadow = true;
    gltf.nodes[`Bone${boneNumber}`].receiveShadow = true;
  }


  gltf.nodes.HT.castShadow = true; 
  gltf.nodes.HT.receiveShadow = true;



  const [mixer, setMixer] = useState(null);

  useEffect(() => {
    if (gltf.animations.length ) {
      const newMixer = new THREE.AnimationMixer(gltf.scene);
      gltf.animations.forEach((clip) => {
        const action = newMixer.clipAction(clip);
        action.reset();
        action.play();
      });
      setMixer(newMixer);
    }
  }, [gltf]);

  
  useFrame((state, delta) => {
    
    if (!show && mixer) {
      mixer.update(delta);
    }
  });

  const orbitControls = useRef();

  const handleClick = () => {
    setShow(!show);
    const elem = document.documentElement;
    if (elem.requestFullscreen) {
      if (!document.fullscreenElement) {
        elem.requestFullscreen().catch(() => {});
      } else {
        document.exitFullscreen();
      }
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen().catch(() => {
        console.error('Fullscreen request failed.');
      });
    } else {
      console.error('Fullscreen is not supported on this device.');
    }
    
    handleBoxClick();
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const audioElement = new Audio('/audio.mp3');
    const audioSource = audioContext.createMediaElementSource(audioElement);
    const gainNode = audioContext.createGain();

    audioSource.connect(gainNode);
    gainNode.connect(audioContext.destination);

    audioElement.play().catch(error => {
      console.error('Failed to play audio1:', error);
    });

    setTimeout(()=> {
      setShow(true)
    },21600)

  };

  const transition = { type: 'spring', duration: 0.8 }
  const config = {
    initial: { x: -100, opacity: 0, transition: { ...transition, delay: 0.5 } },
    animate: { x: 0, opacity: 1, transition: { ...transition, delay: 0 } },
    exit: { x: -100, opacity: 0, transition: { ...transition, delay: 0 } }
  }

  const[show,setShow]=useState(true);

  return (
  <>
  <OrbitControls
      ref={orbitControls}
      enableRotate={true} 
      enableZoom={true}
      enablePan={true}
      alpha={gyroscopeData.alpha} 
      beta={gyroscopeData.beta}
      gamma={gyroscopeData.gamma}
    />
    
    <primitive receiveShadow castShadow wireframe object={gltf.scene}  ref={inputElement}  rotation={[0,3.14,0]} position={[0,-1.5,-1.5]}/>
    
    {show ?(
      <Html>
        <motion.section key="main" {...config}>
              <div className="section--container">
                <motion.div
                  key="title"
                  initial={{ x: 100, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ type: 'spring', damping: 5, stiffness: 40, restDelta: 0.001, duration: 0.3 }}>
                  <h1>LET'S START</h1>
                </motion.div>
                <div className="support--content">
                  <motion.div
                    key="p"
                    initial={{ y: 100, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{
                      type: 'spring',
                      damping: 7,
                      stiffness: 30,
                      restDelta: 0.001,
                      duration: 0.6,
                      delay: 0.2,
                      delayChildren: 0.2
                    }}>
                        
                   
                    <button style={{ background: "red" }} onClick={handleClick}>
                      START EXPERIENCE <AiOutlineHighlight size="1.3em" />
                    </button>
                  </motion.div>
                </div>
              </div>
            </motion.section>
      </Html>
    ): (
      <Html>
      </Html>
    )
    }
  </>
  );
}
export default Gyrobox;

  
import './App.css';
import Gyrobox from "./components/gyrobox";
import { Canvas } from '@react-three/fiber'
import React, { Suspense } from 'react';

function App() {
  window.addEventListener('orientationchange', function() {
    // Reset the transform property
    document.body.style.transform = 'none';
  
    // Your code to handle orientation change
  });
  return (
  <>
    <div className="App" style={{  width: '100vw', height: '100vh',overflow: 'hidden',  backgroundColor: '#000000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Canvas flat  shadows  
        >
          <Suspense fallback={null}>
            <color attach="background" args={['#000000']} />
            <ambientLight args={["#ffffff",0.25]}></ambientLight>
            <directionalLight castShadow 
              color="white"
              position={[0, 80, 80]}
              intensity={2}
              shadow-mapSize-width={1024} 
              shadow-mapSize-height={1024}
            />
            <Gyrobox/>
          </Suspense>
      </Canvas>
    </div>
  </>
    
  );
}

export default App;
//                 <axesHelper args={[10]}></axesHelper>
     
